body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.logo {
  width: 100%;
  padding: 21px 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  height: 100px;
  border-bottom: solid 1px #ffffff2a;
  /* border-right: solid 1px #ffffff2a; */

  /* background: -webkit-linear-gradient(#eee, #333); */
  /* -webkit-background-clip: text; */
  /* -webkit-text-fill-color: transparent; */

  /* background-color: #023399; */
  /* background-image: linear-gradient(132deg, #023399 0%, #3c81f6 100%); */
  color: #fff;

  /* 
  background-color: #f4d03f;
  background-image: linear-gradient(132deg, #f4d03f 0%, #3c81f6 100%); */
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #3c81f6;
  background-image: linear-gradient(132deg, #3c81f6 0%, #023399 100%);
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent !important;
  background-image: linear-gradient(132deg, #3c81f6 0%, #023399 100%);
}

.my-primary {
  background-color: #3c81f6;
  background-image: linear-gradient(132deg, #3c81f6 0%, #023399 100%);
}

.site-badge-count-4 .ant-badge-count {
  color: #999;
  background-color: #fff;
  box-shadow: 0 0 0 1px #d9d9d9 inset;
}
.ant-btn-primary {
  background-color: #023399 !important;
}
.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-has-trigger {
  max-width: 260px !important;
  min-width: 260px !important;
  width: 260px !important;
}
.ant-layout-sider-trigger {
  width: 260px !important;
  display: none;
}
